import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoItemsModule } from '@swan/components/no-items';
import { SwanConfirmDialogModule } from '@swan/lib/components';
import { YukawaAlertModule } from '@yukawa/chain-base-angular-comp/alert';
import { SharedModule as LibSharedModule } from '@yukawa/chain-base-angular-comp/shared';


const components = [
    CommonModule,
    FormsModule,
    NoItemsModule,
    ReactiveFormsModule,
    SwanConfirmDialogModule,
    YukawaAlertModule,
];

@NgModule({
    imports: components,
    exports: [
        ...components,
        LibSharedModule,
    ],
})
export class SharedModule
{
}
