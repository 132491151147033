import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwanLoadingService } from '@swan/services/loading/loading.service';
import { finalize, Observable } from 'rxjs';


@Injectable()
export class SwanLoadingInterceptor implements HttpInterceptor
{
    handleRequestsAutomatically: boolean;

    /**
     * Constructor
     */
    constructor(
        private _swanLoadingService: SwanLoadingService,
    )
    {
        // Subscribe to the auto
        this._swanLoadingService.auto$
            .subscribe((value) =>
            {
                this.handleRequestsAutomatically = value;
            });
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // If the Auto mode is turned off, do nothing
        if (!this.handleRequestsAutomatically) {
            return next.handle(req);
        }

        // Set the loading status to true
        this._swanLoadingService._setLoadingStatus(true, req.url);

        return next.handle(req).pipe(
            finalize(() =>
            {
                // Set the status to false if there are any errors or the request is completed
                this._swanLoadingService._setLoadingStatus(false, req.url);
            }));
    }
}
