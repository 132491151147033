<div matDialogTitle *ngIf="data" transloco="VIDEO.RECORD.TAGS.LABEL" [translocoParams]="{
    selectedTagsCount: selectedInterestsCount,
    maxTagCount: maxTagCount
}"></div>
<div class="flex flex-col w-full space-y-5" [ngClass]="{
    'pb-4': !displayPersonalizeMessage
}">

    <div class="mb-8" *ngIf="displayPersonalizeMessage">
        <h2 transloco="PERSONALIZE.INTEREST.WHAT_ARE_YOU_INTO"
            [translocoParams]="{name: username}"></h2>
        <p class="pt-2" transloco="PERSONALIZE.INTEREST.SELECT"
           [translocoParams]="{ interest: selectedInterest?.name }">
            <span *ngIf="selectedInterest !== null" class="italic" @fadeOut @fadeIn>{{ selectedInterest.name }}</span>
        </p>
    </div>

    <div *ngFor="let interestCategory of interestsView">

        <div class="flex" @fadeIn @fadeOut>

            <h3 class="mb-2">{{interestCategory.name}}</h3>

            <ng-container *ngIf="displayMore">
                <ng-container [ngSwitch]="interestCategory.hasMore">
                    <a *ngSwitchCase="true" title=""
                       class="ml-auto flex items-center font-sm font-sans font-semibold text-gray-500"
                       (click)="selectInterest(interestCategory)">
                        <span transloco="BUTTON.MORE"></span>
                        <mat-icon class="-ml-0.5" svgIcon="swan:navigation-chevron-right"></mat-icon>
                    </a>
                    <ng-container *ngSwitchCase="false">
                        <a *ngIf="selectedInterest !== null"
                           class="ml-auto flex items-center font-sm font-sans font-semibold text-gray-500" title=""
                           (click)="back()">
                            <mat-icon class="-ml-0.5" svgIcon="swan:navigation-chevron-left"></mat-icon>
                            <span transloco="BUTTON.BACK"></span>
                        </a>
                    </ng-container>
                </ng-container>
            </ng-container>

        </div>

        <div *ngIf="interestCategory.interests"
             class="m-0 grid grid-cols-2 sm:grid-cols-4 -ml-2 pr-2">
            <a (click)="toggleInterestTag(interest)" *ngFor="let interest of interestCategory.interests"
               [title]="interest.name" @fadeIn @fadeOut [ngClass]="{
                    'selected': interest.selected || interest.hasSelectedChildren
                 }" class="interest relative ml-2 mt-2 overflow-hidden rounded-lg">
                <img class="interest-image outline object-scale-down place-self-center"
                     [src]="interest.imageUrl" [alt]="">
                <div class="backdrop"></div>
                <div class="backdrop-selected"></div>
                <mat-icon svgIcon="swan:system-check-circle-filled"
                          *ngIf="interest.selected || interest.hasSelectedChildren"
                          class="absolute inline-block top-2 right-2 text-white" [ngClass]="{
                                'text-white': interest.selected,
                                'text-gray-500': !interest.selected && interest.hasSelectedChildren
                          }"></mat-icon>
                <span
                    class="absolute inline-block bottom-2.5 left-3 font-sans font-semibold tracking-wide leading-4 text-base text-white">{{ interest.name }}</span>
            </a>
        </div>

    </div>

</div>
