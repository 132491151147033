<!-- Item wrapper -->
<div
    class="swan-horizontal-navigation-item-wrapper"
    [class.swan-horizontal-navigation-item-has-subtitle]="!!item.subtitle"
    [ngClass]="item.classes?.wrapper">

    <!-- Item with an internal link -->
    <ng-container *ngIf="item.link && !item.externalLink && !item.function && !item.disabled">
        <div
            class="swan-horizontal-navigation-item"
            [ngClass]="{'swan-horizontal-navigation-item-active-forced': item.active}"
            [routerLink]="[item.link]"
            [routerLinkActive]="'swan-horizontal-navigation-item-active'"
            [routerLinkActiveOptions]="isActiveMatchOptions"
            [matTooltip]="item.tooltip || ''">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an external link -->
    <ng-container *ngIf="item.link && item.externalLink && !item.function && !item.disabled">
        <a
            class="swan-horizontal-navigation-item"
            [href]="item.link"
            [target]="item.target || '_self'"
            [matTooltip]="item.tooltip || ''">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a function -->
    <ng-container *ngIf="!item.link && item.function && !item.disabled">
        <div
            class="swan-horizontal-navigation-item"
            [ngClass]="{'swan-horizontal-navigation-item-active-forced': item.active}"
            [matTooltip]="item.tooltip || ''"
            (click)="item.function(item)">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an internal link and function -->
    <ng-container *ngIf="item.link && !item.externalLink && item.function && !item.disabled">
        <div
            class="swan-horizontal-navigation-item"
            [ngClass]="{'swan-horizontal-navigation-item-active-forced': item.active}"
            [routerLink]="[item.link]"
            [routerLinkActive]="'swan-horizontal-navigation-item-active'"
            [routerLinkActiveOptions]="isActiveMatchOptions"
            [matTooltip]="item.tooltip || ''"
            (click)="item.function(item)">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an external link and function -->
    <ng-container *ngIf="item.link && item.externalLink && item.function && !item.disabled">
        <a
            class="swan-horizontal-navigation-item"
            [href]="item.link"
            [target]="item.target || '_self'"
            [matTooltip]="item.tooltip || ''"
            (click)="item.function(item)"
            mat-menu-item>
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a no link and no function -->
    <ng-container *ngIf="!item.link && !item.function && !item.disabled">
        <div
            class="swan-horizontal-navigation-item"
            [ngClass]="{'swan-horizontal-navigation-item-active-forced': item.active}"
            [matTooltip]="item.tooltip || ''">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item is disabled -->
    <ng-container *ngIf="item.disabled">
        <div class="swan-horizontal-navigation-item swan-horizontal-navigation-item-disabled">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

</div>

<!-- Item template -->
<ng-template #itemTemplate>

    <!-- Icon -->
    <ng-container *ngIf="item.icon">
        <mat-icon
            class="swan-horizontal-navigation-item-icon"
            [ngClass]="item.classes?.icon"
            [svgIcon]="item.icon"></mat-icon>
    </ng-container>

    <!-- Title & Subtitle -->
    <div class="swan-horizontal-navigation-item-title-wrapper">
        <div class="swan-horizontal-navigation-item-title">
            <span [ngClass]="item.classes?.title">
                {{item.title}}
            </span>
        </div>
        <ng-container *ngIf="item.subtitle">
            <div class="swan-horizontal-navigation-item-subtitle text-hint">
                <span [ngClass]="item.classes?.subtitle">
                    {{item.subtitle}}
                </span>
            </div>
        </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
        <div class="swan-horizontal-navigation-item-badge">
            <div
                class="swan-horizontal-navigation-item-badge-content"
                [ngClass]="item.badge.classes">
                {{item.badge.title}}
            </div>
        </div>
    </ng-container>

</ng-template>
