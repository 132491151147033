import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwanConfigService } from '@swan/services/config';
import { SwanMediaWatcherService } from '@swan/services/media-watcher';
import { SWAN_VERSION } from '@swan/version';
import { AppConfig } from 'app/core/config/app.config';
import { SettingsComponent } from 'app/layout/common/settings/settings.component';
import { Layout, LayoutRouteConfig, LayoutScheme } from 'app/layout/layout.types';
import { combineLatest, filter, map, Subject, takeUntil } from 'rxjs';


@Component({
    selector     : 'layout',
    templateUrl  : './layout.component.html',
    styleUrls    : ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit, OnDestroy
{
    @ViewChild(SettingsComponent) settingsComponent: SettingsComponent;

    config: AppConfig;
    layout: Layout;
    scheme: LayoutScheme;
    theme: string;
    displayErrorAlerts: boolean = true;

    #unsubscribeAll: Subject<any> = new Subject<any>();

    private _bodyClass: string;

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _activatedRoute: ActivatedRoute,
        private _swanConfigService: SwanConfigService,
        private _swanMediaWatcherService: SwanMediaWatcherService,
        private _renderer2: Renderer2,
        private _router: Router,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Set the theme and scheme based on the configuration
        combineLatest([
            this._swanConfigService.config$,
            this._swanMediaWatcherService.onMediaQueryChange$(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)']),
        ]).pipe(
            takeUntil(this.#unsubscribeAll),
            map(([config, mql]) =>
            {

                const options = {
                    scheme: config.scheme,
                    theme : config.theme,
                };

                // If the scheme is set to 'auto'...
                if (config.scheme === 'auto') {
                    // Decide the scheme using the media query
                    options.scheme = mql.breakpoints['(prefers-color-scheme: dark)'] ? 'dark' : 'light';
                }

                return options;
            }),
        ).subscribe((options) =>
        {

            // Store the options
            this.scheme = options.scheme;
            this.theme  = options.theme;

            // Update the scheme and theme
            this._updateScheme();
            this._updateTheme();
        });

        // Subscribe to config changes
        this._swanConfigService.config$
            .pipe(takeUntil(this.#unsubscribeAll))
            .subscribe((config: AppConfig) =>
            {

                // Store the config
                this.config = config;

                // Update the layout
                this._updateLayout();
            });

        // Subscribe to NavigationEnd event
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this.#unsubscribeAll),
        ).subscribe(() =>
        {

            // Update the layout
            this._updateLayout();
        });

        // Set the app version
        this._renderer2.setAttribute(this._document.querySelector('[ng-version]'), 'swan-version', SWAN_VERSION);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.#unsubscribeAll.next(null);
        this.#unsubscribeAll.complete();
    }

    toggleSettings(): void
    {
        this.settingsComponent.toggleDrawer();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the selected layout
     */
    private _updateLayout(): void
    {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        // 1. Set the layout from the config
        this.layout = this.config.layout;

        // 2. Get the query parameter from the current route and
        // set the layout and save the layout to the config
        const layoutFromQueryParam = (route.snapshot.queryParamMap.get('layout') as Layout);
        if (layoutFromQueryParam) {
            this.layout = layoutFromQueryParam;
            if (this.config) {
                this.config.layout = layoutFromQueryParam;
            }
        }

        // 3. Iterate through the paths and change the layout as we find
        // a config for it.
        //
        // The reason we do this is that there might be empty grouping
        // paths or componentless routes along the path. Because of that,
        // we cannot just assume that the layout configuration will be
        // in the last path's config or in the first path's config.
        //
        // So, we get all the paths that matched starting from root all
        // the way to the current activated route, walk through them one
        // by one and change the layout as we find the layout config. This
        // way, layout configuration can live anywhere within the path and
        // we won't miss it.
        //
        // Also, this will allow overriding the layout in any time so we
        // can have different layouts for different routes.
        const paths = route.pathFromRoot;
        paths.forEach((path) =>
        {

            // Check if there is a 'layout' data
            const data = path.routeConfig?.data as LayoutRouteConfig;
            if (data) {

                this.displayErrorAlerts = data.errorAlerts ?? true;

                // Set/remove body classes
                const bodyClass = data.bodyClass ?? null;
                if (bodyClass) {
                    window.document.body.classList.add(...bodyClass.split(' '));
                    this._bodyClass = bodyClass;
                }
                else if (this._bodyClass) {
                    window.document.body.classList.remove(...this._bodyClass.split(' '));
                }

                // Set the layout
                if (data.layout) {
                    this.layout = data.layout;
                    this.scheme = data.scheme ? data.scheme : this.scheme;
                    this.theme  = data.theme ? data.theme : this.theme;
                    this._updateScheme();
                    this._updateTheme();
                }
            }
        });
    }

    /**
     * Update the selected scheme
     *
     * @private
     */
    private _updateScheme(): void
    {
        // Remove class names for all schemes
        this._document.body.classList.remove('light', 'dark');

        // Add class name for the currently selected scheme
        this._document.body.classList.add(this.scheme);
    }

    /**
     * Update the selected theme
     *
     * @private
     */
    private _updateTheme(): void
    {
        // Find the class name for the previously selected theme and remove it
        this._document.body.classList.forEach((className: string) =>
        {
            if (className.startsWith('theme-')) {
                this._document.body.classList.remove(className, className.split('-')[1]);
            }
        });

        // Add class name for the currently selected theme
        this._document.body.classList.add(this.theme);
    }
}
