<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Centered -->
<centered-layout *ngIf="layout === 'centered'"></centered-layout>

<!-- Enterprise -->
<enterprise-layout *ngIf="layout === 'enterprise'"></enterprise-layout>

<!-- Material -->
<material-layout *ngIf="layout === 'material'"></material-layout>

<!-- Modern -->
<modern-layout *ngIf="layout === 'modern'"></modern-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Classic -->
<classic-layout *ngIf="layout === 'classic'"></classic-layout>

<!-- Classy -->
<classy-layout *ngIf="layout === 'classy'"></classy-layout>

<!-- Compact -->
<compact-layout *ngIf="layout === 'compact'"></compact-layout>

<!-- Dense -->
<dense-layout *ngIf="layout === 'dense'"></dense-layout>

<!-- Futuristic -->
<futuristic-layout *ngIf="layout === 'futuristic'"></futuristic-layout>

<!-- Thin -->
<thin-layout *ngIf="layout === 'thin'"></thin-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<settings></settings>

<yukawa-console-log-alerts *ngIf="displayErrorAlerts"></yukawa-console-log-alerts>

<!-- Alert -->
<yukawa-alert
    #alert
    name="global"
    class="absolute block ml-auto mr-auto left-15 lg:left-0 top-10 right-15 lg:right-0 w-auto lg:w-200 h-auto"
    [ngClass]="{ 'min-h-15': !alert.dismissed }"
    style="z-index: 99999"
    appearance="outline"
    showIcon="true"
    dismissible="true"
    dismissed="true">
</yukawa-alert>
