<!-- Items list -->
<ng-container *ngIf="itemsAvailable; else noItems">

</ng-container>

<!-- No items template -->
<ng-template #noItems>
    <div class="flex flex-auto flex-col items-center justify-center bg-gray-100 dark:bg-transparent">
        <mat-icon
            class="icon-size-24"
            [svgIcon]="'iconsmind:file_hide'"></mat-icon>
        <div class="mt-4 text-2xl font-semibold tracking-tight text-secondary">There are no items!</div>
    </div>
</ng-template>
