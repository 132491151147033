import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Role, UserService } from '@swan/lib/profile';
import { ProductDetailsComponent } from 'app/modules/admin/product/comp/product-details/product-details.component';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class CanDeactivateProductDetails implements CanDeactivate<ProductDetailsComponent>
{
    canDeactivate(
        component: ProductDetailsComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        // Get the next route
        let nextRoute: ActivatedRouteSnapshot = nextState.root;
        while (nextRoute.firstChild) {
            nextRoute = nextRoute.firstChild;
        }

        if (!nextState.url.includes('/product')) {
            return true;
        }

        if (nextState.url.includes('/details')) {
            return true;
        }
        else {
            component.close();
            return true;
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class CanActivateProduct implements CanActivate
{
    constructor(
        private readonly _userService: UserService,
    )
    {
    }

    public canActivate(
        route?: ActivatedRouteSnapshot,
        state?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._userService.hasRole(Role.productEdit);
    }
}
