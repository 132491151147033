import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SwanLoadingModule } from '@swan/services/loading';
import { SwanMediaWatcherModule } from '@swan/services/media-watcher/media-watcher.module';
import { SwanUtilsModule } from '@swan/services/utils/utils.module';
import { YukawaConfirmationModule } from '@yukawa/chain-base-angular-comp/confirmation';


@NgModule({
    imports  : [
        SwanLoadingModule,
        SwanMediaWatcherModule,
        SwanUtilsModule,
        YukawaConfirmationModule,
    ],
    providers: [
        {
            // Disable 'theme' sanity check
            provide : MATERIAL_SANITY_CHECKS,
            useValue: {
                doctype: true,
                theme  : false,
                version: true,
            },
        },
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide : MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill',
            },
        },
    ],
})
export class SwanModule
{
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: SwanModule)
    {
        if (parentModule) {
            throw new Error('SwanModule has already been loaded. Import this module in the AppModule only!');
        }
    }
}
