import { Injectable } from '@angular/core';
import { HideOptions, ShowOptions, SplashScreen as CapacitorSplashScreen } from '@capacitor/splash-screen';


@Injectable({
    providedIn: 'root',
})
export class SplashScreen
{
    public async show(options: ShowOptions = {
        autoHide: false,
    }): Promise<void>
    {
        return CapacitorSplashScreen.show(options);
    }

    public async hide(options: HideOptions): Promise<void>
    {
        return CapacitorSplashScreen.hide(options);
    }
}
