import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SwanScrollbarModule } from '@swan/directives/scrollbar/public-api';
import { SwanHorizontalNavigationBasicItemComponent } from '@swan/components/navigation/horizontal/components/basic/basic.component';
import { SwanHorizontalNavigationBranchItemComponent } from '@swan/components/navigation/horizontal/components/branch/branch.component';
import { SwanHorizontalNavigationDividerItemComponent } from '@swan/components/navigation/horizontal/components/divider/divider.component';
import { SwanHorizontalNavigationSpacerItemComponent } from '@swan/components/navigation/horizontal/components/spacer/spacer.component';
import { SwanHorizontalNavigationComponent } from '@swan/components/navigation/horizontal/horizontal.component';
import { SwanVerticalNavigationAsideItemComponent } from '@swan/components/navigation/vertical/components/aside/aside.component';
import { SwanVerticalNavigationBasicItemComponent } from '@swan/components/navigation/vertical/components/basic/basic.component';
import { SwanVerticalNavigationCollapsableItemComponent } from '@swan/components/navigation/vertical/components/collapsable/collapsable.component';
import { SwanVerticalNavigationDividerItemComponent } from '@swan/components/navigation/vertical/components/divider/divider.component';
import { SwanVerticalNavigationGroupItemComponent } from '@swan/components/navigation/vertical/components/group/group.component';
import { SwanVerticalNavigationSpacerItemComponent } from '@swan/components/navigation/vertical/components/spacer/spacer.component';
import { SwanVerticalNavigationComponent } from '@swan/components/navigation/vertical/vertical.component';

@NgModule({
    declarations: [
        SwanHorizontalNavigationBasicItemComponent,
        SwanHorizontalNavigationBranchItemComponent,
        SwanHorizontalNavigationDividerItemComponent,
        SwanHorizontalNavigationSpacerItemComponent,
        SwanHorizontalNavigationComponent,
        SwanVerticalNavigationAsideItemComponent,
        SwanVerticalNavigationBasicItemComponent,
        SwanVerticalNavigationCollapsableItemComponent,
        SwanVerticalNavigationDividerItemComponent,
        SwanVerticalNavigationGroupItemComponent,
        SwanVerticalNavigationSpacerItemComponent,
        SwanVerticalNavigationComponent
    ],
    imports     : [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        SwanScrollbarModule
    ],
    exports     : [
        SwanHorizontalNavigationComponent,
        SwanVerticalNavigationComponent
    ]
})
export class SwanNavigationModule
{
}
