<div class="relative" [style.width.px]="size" [style.height.px]="size">

    <div class="rounded-full" style="background: #F5F5F5">

        <round-progress *ngIf="percentage < 100"
                        [current]="percentage"
                        [max]="100"
                        [color]="'#D12100'"
                        [background]="'#FFE0DA'"
                        [radius]="size / 2"
                        [stroke]="2"
                        [rounded]="true"
                        [clockwise]="true"
                        [responsive]="false"
                        [duration]="800"
                        [animation]="'easeInOutQuart'"
                        [animationDelay]="0"
        >
        </round-progress>

        <ng-container [ngSwitch]="profileImageAvailable">

            <mat-icon *ngSwitchCase="false" svgIcon="swan:system-profile" class="text-gray-500"></mat-icon>

            <lib-user-icon #userIcon *ngSwitchCase="true" [size]="44" class="m-auto w-11"
                           [displayVerified]="true" [profile]="profile"></lib-user-icon>

        </ng-container>

    </div>
</div>

<div *ngIf="percentage < 100" class="mt-1 text-xs font-bold font-sans text-gray-500" @fadeIn @fadeOut
     transloco="PROFILE.COMPLETED" [translocoParams]="{ percentage: percentage }">
</div>
