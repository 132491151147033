import { NgModule } from '@angular/core';
import { SwanUtilsService } from '@swan/services/utils/utils.service';

@NgModule({
    providers: [
        SwanUtilsService
    ]
})
export class SwanUtilsModule
{
    /**
     * Constructor
     */
    constructor(private _swanUtilsService: SwanUtilsService)
    {
    }
}
