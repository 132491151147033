import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
    selector       : 'app-no-items',
    templateUrl    : './no-items.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoItemsComponent
{

    @Input()
    public itemsAvailable: boolean = false;

    constructor()
    {
    }

}
