import { Injectable } from '@angular/core';
import { ObjectModel } from '@awesome-nodes/mvvm/model';
import { AnimationOptions, StatusBar as CapacitorStatusBar, StatusBarInfo, Style } from '@capacitor/status-bar';
import { EventDelegate } from '@yukawa/chain-base-angular-client';
import { UAParser } from 'ua-parser-js';


@Injectable({
    providedIn: 'root',
})
export class StatusBar extends ObjectModel
{
    private _info!: StatusBarInfo;
    private _statusTap         = new EventDelegate(this);
    private _overlays: boolean = false;
    private _visible: boolean  = false;

    private readonly _uaParser = new UAParser(navigator.userAgent);

    public constructor()
    {
        super();

        // iOS only
        window.addEventListener('statusTap', () =>
        {
            this._statusTap.invoke();
        });
    }

    public get info(): StatusBarInfo
    {
        return this._info;
    }

    public get statusTap(): EventDelegate<this>
    {
        return this._statusTap;
    }

    public get overlays(): boolean
    {
        return this._overlays;
    }

    public get visible(): boolean
    {
        return this._visible;
    }

    public async getInfo(): Promise<StatusBarInfo>
    {
        return this._info = await CapacitorStatusBar.getInfo();
    };

    /**
     * Display content under transparent status bar (Android only)
     *
     * @param overlay
     */
    public async setOverlaysWebView(overlay: boolean): Promise<void>
    {
        if (this._uaParser.getOS().name !== 'iOS') {
            await CapacitorStatusBar.setOverlaysWebView({ overlay });
        }
        this._overlays = overlay;
    }

    public async setStyleDark(): Promise<void>
    {
        await CapacitorStatusBar.setStyle({ style: Style.Dark });
    };

    public async setStyleLight(): Promise<void>
    {
        await CapacitorStatusBar.setStyle({ style: Style.Light });
    };

    public async hide(options: AnimationOptions): Promise<void>
    {
        await CapacitorStatusBar.hide(options);
        this._visible = false;
    };

    public async show(options: AnimationOptions): Promise<void>
    {
        await CapacitorStatusBar.show(options);
        this._visible = true;
    };
}
