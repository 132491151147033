<div class="flex flex-col w-full h-full">

    <div class="space-y-5 mb-5">

        <div class="relative w-min">

            <mat-icon svgIcon="swan:favicon" class="icon-size-25 overflow-hidden"></mat-icon>

            <span id="notification-count"
                  class="absolute inline-block -top-4 -right-4 w-10 h-10 rounded-full border-4 border-white text-white inline-flex items-center place-content-center font-sans font-semibold text-2xl">1
        </span>

        </div>

        <h2 transloco="PERSONALIZE.CONSENT.LAST_THING"></h2>

        <ul class="ml-5 list-disc text-base font-sans space-y-4">

            <li class="" transloco="PERSONALIZE.CONSENT.CURRENT_OFFERS"
                [ngClass]="{ selected: profile.currentOffersConsent }"></li>

            <li class="" transloco="PERSONALIZE.CONSENT.HOT_NEWS"
                [ngClass]="{ selected: profile.latestNewsConsent }"></li>

            <li class="" transloco="PERSONALIZE.CONSENT.NEW_FOLLOWER"
                [ngClass]="{ selected: profile.latestNewsConsent }"></li>

            <li class="" transloco="PERSONALIZE.CONSENT.COMMENTS_AND_LIKES"
                [ngClass]="{ selected: profile.latestNewsConsent }"></li>

        </ul>

    </div>

    <div class="flex flex-col mt-auto" *ngIf="showButtons" @expandCollapse>
        <p class="mt-auto font-sans font-medium text-sm pb-4 text-gray-500 opacity-60"
           [innerHTML]='"PERSONALIZE.CONSENT.DISCLAIMER_NOTICE" | transloco: {
                "imIn": ("PERSONALIZE.CONSENT.IM_IN" | transloco)
            }'>
        </p>

        <ng-container [ngSwitch]="profile.pushNotifications === null || !profile.pushNotifications">
            <button *ngSwitchCase="true" mat-flat-button color="primary" class="swan-mat-button-large tracking-wide"
                    [disabled]="disabled" (click)="imIn()" title="">
                {{ 'PERSONALIZE.CONSENT.IM_IN' | transloco }}
            </button>
            <button *ngSwitchCase="false" mat-flat-button color="primary" class="swan-mat-button-large tracking-wide"
                    [disabled]="disabled" (click)="imOut()" title="">
                {{ 'PERSONALIZE.CONSENT.IM_OUT' | transloco }}
            </button>
        </ng-container>
        <button mat-flat-button class="swan-mat-button-large mt-4 text-black bg-gray-100 font-bold tracking-wide"
                [disabled]="disabled" (click)="maybeLater()" title="">
            {{ 'PERSONALIZE.CONSENT.MAYBE_LATER' | transloco }}
        </button>
    </div>

</div>
