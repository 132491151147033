import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { MarketRegion } from '@swan/lib/domain';
import { MockApiService } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import {
    compactNavigation,
    defaultNavigation,
    futuristicNavigation,
    horizontalNavigation,
} from 'app/mock-api/common/navigation/data';
import { cloneDeep } from 'lodash-es';

const MOCK_DATA: MarketRegion[] = [{
    name: "Germany",
    regionId: "666",
    currency: "EUR",
},
{
    name: "USA",
    regionId: "999",
    currency: "USD",
}]

@Injectable({
    providedIn: 'root'
})
export class MarketRegionMockApi {

    /**
     * Constructor
     */
    constructor(private _swanMockApiService: MockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {

        this._swanMockApiService
            .onGet('https://swan.dev.cloud.yukawa.de/api/commerce-service/admin/market-region/')
            .reply(({ request }) => {
                const entry = MOCK_DATA.find((item) => item.regionId === request.params.get('key'))
                return [200, entry];
            })

        this._swanMockApiService
            .onPut('https://swan.dev.cloud.yukawa.de/api/commerce-service/admin/market-region/')
            .reply(({ request }) => {
                const entryIdx = MOCK_DATA.findIndex((item) => item.regionId === request.params.get('key'))

                if (request.body.freeShippingThreshold === undefined) {
                    delete MOCK_DATA[entryIdx].freeShippingThreshold;
                }
                if (MOCK_DATA[entryIdx]) {
                    MOCK_DATA[entryIdx] = Object.assign(MOCK_DATA[entryIdx], request.body)
                }
                return [200, MOCK_DATA[entryIdx]]
            })
    }
}
