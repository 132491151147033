import { NgModule } from '@angular/core';
import { SwanMediaWatcherService } from '@swan/services/media-watcher/media-watcher.service';
import { MEDIA_WATCHER_SERVICE } from '@yukawa/chain-base-angular-comp/shared';


@NgModule({
    providers: [
        SwanMediaWatcherService,
        {
            provide    : MEDIA_WATCHER_SERVICE,
            useExisting: SwanMediaWatcherService,
        },
    ]
})
export class SwanMediaWatcherModule
{
    /**
     * Constructor
     */
    constructor(private _swanMediaWatcherService: SwanMediaWatcherService)
    {
    }
}
