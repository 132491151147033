<div class="flex flex-col w-full">

    <div>
        <h2 transloco="PERSONALIZE.SKIN_TYPE.YOUR_SKIN_TYPE"></h2>
        <p class="pt-2" transloco="PERSONALIZE.SKIN_TYPE.SKIN_HAS_OWN_NEEDS"></p>
    </div>

    <mat-selection-list class="pt-6 space-y-2" (selectionChange)="skinTypeChanged($event)" [disabled]="disabled">

        <mat-list-option *ngFor="let _skinType of skinTypes"
                         [selected]="skinType === _skinType.name" [value]="_skinType"
                         class="no-padding border rounded-lg text-sm tracking-wide">
            <div class="flex flex-row">
                <img class="block object-cover w-16"
                     [src]="'assets/images/skinTypes/' + _skinType.name + '.jpg'" [alt]="_skinType.name">
                <div class="ml-5 pt-4">
                    <h4 class="font-bold" transloco="PERSONALIZE.SKIN_TYPE.TYPE"
                        [translocoParams]="{number: _skinType.type}"></h4>
                    <span class="font-sans text-gray-500"
                          [transloco]="'PERSONALIZE.SKIN_TYPE.' + _skinType.name"></span>
                </div>
            </div>
        </mat-list-option>

    </mat-selection-list>

</div>
