import { Injectable, NgZone } from '@angular/core';
import { App } from '@capacitor/app';
import { BackButtonListenerEvent } from '@capacitor/app/dist/esm/definitions';
import { Exception } from '@yukawa/chain-base-angular-client';
import IosSwipeBack from 'capacitor-plugin-ios-swipe-back';
import * as Cordova from 'cordova';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { ObjectType, StringKeys } from 'simplytyped';
import { AndroidPermissions } from './android-permissions';
import { AndroidPermissionsPlugin, ICordovaPluginAndroidPermissions } from './android-permissions-plugin';
import './android-permissions-plugin';
import { ICordovaService } from './cordova';
import { KioskPlugin } from './kiosk-plugin';
import { WifiManagerPlugin } from './wifi-manager-plugin';


@Injectable()
/**
 * @see https://medium.com/@EliaPalme/how-to-wrap-an-angular-app-with-apache-cordova-909024a25d79
 */
export class CordovaService implements ICordovaService
{
    private resume: BehaviorSubject<boolean>;

    constructor(private readonly _zone: NgZone)
    {
        this.resume = new BehaviorSubject<boolean>(false);
        fromEvent(document, 'resume').subscribe((event) =>
        {
            this._zone.run(() =>
            {
                this.onResume();
            });
        });
        if (this.onCordova && this.cordova.plugins) {
            console.log(this.cordova.plugins.permissions);
        }
        // Do NEVER trigger cordova actions here because of asynchronicity
    }

    get cordova(): Cordova
    {
        return window.cordova;
    }

    get onCordova(): boolean
    {
        return !!window.cordova;
    }

    public onResume(): void
    {
        this.resume.next(true);
    }

    // -----------------------------------------------------------------------------------------------------------------
    // PERMISSION

    /**
     * https://www.npmjs.com/package/cordova-plugin-android-permissions
     *
     * @param name
     * @param onSuccess
     * @param onError
     */
    public requestPermission(
        name: AndroidPermissions,
        onSuccess?: AndroidPermissionsPlugin.CallbackFunction,
        onError?: AndroidPermissionsPlugin.CallbackFunction): void
    {
        if (!this.onCordova || !this.cordova.plugins?.permissions) {
            console.warn('permissions not supported by platform. ' + name);
            return;
        }
        const permissions = this.cordova.plugins.permissions as ObjectType<ICordovaPluginAndroidPermissions>;
        if (onError == null) {
            onError = (result): void =>
            {
                console.warn('permission ' + name + ' is not turned on');
            };
        }
        if (onSuccess == null) {
            onSuccess = (status): void =>
            {
                if (!status.hasPermission) {
                    if (onError) {
                        onError(status);
                    }
                }
                else {
                    console.log(`permission '${name}' is granted`, status);
                }
            };
        }
        const permission = permissions[name.split('.').pop() as string as StringKeys<ICordovaPluginAndroidPermissions>] as AndroidPermissions;
        if (!permission) {
            const message = `Permission ${name} not found`;
            console.warn(message);
            onError({
                hasPermission: false,
                message      : message,
            });
            return;
        }
        console.log('Requesting permission ' + permission);
        permissions.requestPermission(permission, onSuccess, onError);
    }

    public async requestPermissions(...permissions: Array<AndroidPermissions>): Promise<void>
    {
        for (const _permission of permissions) {
            await this.promisify(this.requestPermission, _permission);
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // WIFI

    /**
     * https://www.npmjs.com/package/cordova-plugin-wifi-manager
     *
     * @param ssid
     * @param pass
     * @param onSuccess callback function ()
     * @param onError callback  function (error)
     */
    public connectToWifi(
        ssid: string,
        pass: string,
        onSuccess: WifiManagerPlugin.SuccessCallback,
        onError: WifiManagerPlugin.FailureCallback,
    ): void
    {
        // @toDo NR - what type do we have to use for onSuccess / onError ?
        const wifi = window.wifiManager;
        if (wifi) {
            console.log('CordovaService connectToWifi: connecting to ' + ssid);
            wifi.connect(
                ssid,
                pass,
                onSuccess,
                onError,
            );
        }
        else {
            console.error('CordovaService connectToWifi: wifiManager not found');
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // WIFI PLUGIN
    // https://openbase.com/js/cordova-wifi-plugin/documentation#installation
    // - only WPA supported
    // - unable to connect or scan

    // public connectToWifi_2(ssid:string, pass:string, onSuccess?, onError?): void {
    //     if(!this.onCordova || !this.cordova.plugins.WifiManagerPlugin) {
    //         console.warn("WifiManagerPlugin not supported by platform.");
    //         return;
    //     }
    //     let wifi = this.cordova.plugins.WifiManagerPlugin;
    //     wifi.startWifiScan(value => {console.log("OK", value)}, value => {console.log("ERROR", value)});
    //     wifi.connect(value => {console.log("OK", value)}, value => {console.log("ERROR", value)}, 'ChaosFiends', "pass");
    //     wifi.getAvailableNetworksList(value => {console.log("OK", value)}, value => {console.log("ERROR", value)}, true);
    // }

    // -----------------------------------------------------------------------------------------------------------------
    // WIFI WIZARD2
    // https://github.com/tripflex/WifiWizard2
    // - unable to connect or scan

    // public connectToWifi_3(ssid:string, pass:string, onSuccess?, onError?): void {
    //     let wifi = window['WifiWizard2'];
    //     wifi.listNetworks().then(value => {
    //         console.log("CordovaService connectToWifi2", value);
    //     });
    //     wifi.connect('ChaosFiends', true, 'pass', 'WPA').then(value => {
    //         console.log("CordovaService connectToWifi2", value);
    //     });
    //     wifi.timeout(4000).then(value => {
    //         console.log("CordovaService connectToWifi2", value);
    //     });
    // }

    // -----------------------------------------------------------------------------------------------------------------
    // KIOSK PLUGIN by yukawa

    public setKioskEnabled(
        enabled: boolean,
        onSuccess?: KioskPlugin.SuccessCallback,
        onError?: KioskPlugin.FailureCallback): void
    {
        console.log('CordovaService setKioskEnabled ' + enabled);
        if (!onError) {
            onError = (ex): void =>
            {
                console.warn('kiosk enabled error', ex);
            };
        }
        if (!onSuccess) {
            onSuccess = (status): void =>
            {
                console.log('kiosk enabled ' + enabled, status);
            };
        }

        if (!this.onCordova) {
            onError(new Exception('Not on cordova'));
            return;
        }

        const kiosk = window.KioskPlugin;
        if (kiosk) {
            console.log('CordovaService setKioskEnabled ' + enabled);
            if (enabled) {
                kiosk.lock(onSuccess, onError);
            }
            else {
                kiosk.unlock(onSuccess, onError);
            }
        }
        else {
            onError(new Exception('CordovaService setKioskEnabled KioskPlugin not found'));
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // KIOSK LAUNCHER
    // https://github.com/guatedude2/cordova-plugin-kiosk-launcher
    // https://github.com/hkalina/cordova-plugin-kiosk
    // - wifi popup suppressed
    // - back button visible
    // - hides quick bar on expand

    /*public setKioskEnabled(enabled:boolean) : void{
        let kiosk = window['Kiosk'];
        if(kiosk) {
            console.log("CordovaService setKioskEnabled " + enabled);
            kiosk.setKioskEnabled(enabled);
        } else {
            console.error("CordovaService setKioskEnabled Kiosk not found");
        }
    }*/

    // -----------------------------------------------------------------------------------------------------------------
    // KIOSK PLUGIN IA
    // https://www.npmjs.com/package/cordova-plugin-kiosk-ia
    // - same problems as above

    // public setKioskEnabled(enabled:boolean) : void{
    //     let kiosk = window['KioskPlugin'];
    //     if(kiosk) {
    //         console.log("CordovaService setKioskEnabled " + enabled);
    //         kiosk.setKioskEnabled(enabled);
    //     } else {
    //         console.error("CordovaService setKioskEnabled Kiosk not found");
    //     }
    // }

    // -----------------------------------------------------------------------------------------------------------------
    // KIOSK MODE
    // https://github.com/amicaldo/cordova-plugin-kiosk-mode#readme
    // - wifi popup suppressed
    // - quick bar still shown on top swipe
    // - back button on swipe up bottom only on sign-in page
    // - status bar hidden on sign-in but visible after login

    /*    public setKioskEnabled(enabled:boolean) : void {
            let kiosk = window['KioskMode'];
            if(kiosk) {
                console.log("CordovaService setKioskEnabled " + enabled);
                let p : Promise<any>;
                if(enabled) {
                    p = kiosk.enable();
                } else {
                    p = kiosk.disable();
                }
                p.then(value => {
                    console.log("CordovaService setKioskEnabled " + enabled + " => "+value);
                })

            } else {
                console.error("CordovaService setKioskEnabled Kiosk not found");
            }
        }*/

    public async backButton(): Promise<Observable<BackButtonListenerEvent>>
    {
        try {
            await IosSwipeBack.enable({ error: 1 });
        }
        catch (error) {
            console.error(error === 1 ? 'IosSwipeBack failed' : error);
        }

        return new Observable((subscriber) =>
        {
            App.addListener('backButton', (event: BackButtonListenerEvent) =>
            {
                this._zone.run(() =>
                {
                    subscriber.next(event);
                });

            });
        });
    }

    /**
     * Utility function to convert a cordova command to a promise.
     *
     * @param commandFunction
     * @param commandArgs
     */
    public promisify<T>(
        commandFunction: (...args: Array<any>) => void,
        ...commandArgs: Array<unknown>): Promise<T>
    {
        return new Promise<T>((resolve, reject) =>
        {
            try {
                commandFunction.apply(this, [
                    ...commandArgs,
                    ((result: T): void =>
                    {
                        resolve(result);
                    }),
                    (error: unknown): void =>
                    {
                        reject(error);
                    },
                ]);
            }
            catch (error) {
                reject(error);
            }
        });
    }
}
