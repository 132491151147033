<div class="flex flex-col w-full h-full">
    <h2>
        {{ heading }}

        <ng-container *ngFor="let _subHeading of subHeadings;let i=index">
            <span @fadeIn class="text-red-400"
                  *ngIf="subHeadingIndex === i"
            >{{ _subHeading }}</span>
        </ng-container>

    </h2>

    <div class="relative w-full h-full">
        <ng-lottie [options]="options" class="fixed left-0 top-0 right-0 bottom-0"></ng-lottie>
    </div>

</div>
