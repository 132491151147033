import { NgModule } from '@angular/core';
import { SwanScrollbarDirective } from '@swan/directives/scrollbar/scrollbar.directive';

@NgModule({
    declarations: [
        SwanScrollbarDirective
    ],
    exports     : [
        SwanScrollbarDirective
    ]
})
export class SwanScrollbarModule
{
}
