import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwanDrawerComponent } from '@swan/components/drawer/drawer.component';

@NgModule({
    declarations: [
        SwanDrawerComponent
    ],
    imports     : [
        CommonModule
    ],
    exports     : [
        SwanDrawerComponent
    ]
})
export class SwanDrawerModule
{
}
